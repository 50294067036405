.option-button {
	border-radius: 25%;
	width: 34px;
	height: 34px;
	background-color: var(--button-color);
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
}

.option-buttons {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

.option-container {
	padding-left: 5px;
	padding-right: 5px;
	display: flex;
	justify-content: center;
}

@media only screen and (max-width: 877px) {
	.option-buttons,
	.option-container {
		display: flex;
		flex-direction: row;
		height: 54px;
		justify-content: flex-start;
		padding-left: 0px;
		padding-right: 10px;
		align-items: center;
	}
}
