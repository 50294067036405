.nav {
	display: flex;
	justify-content: space-around;
	align-items: center;
	height: 60px;
	/* opacity: 0; */
	background-color: var(--nav-color);
	border-bottom: 1px solid var(--border);
	animation: bgFadeIn 0.7s ease;
}

h4 {
	margin: 0;
}

.logo {
	color: var(--nav-secondary-color);
	text-transform: uppercase;
	letter-spacing: 5px;
	font-size: 20px;
}

.nav-links,
.user-menu {
	display: flex;
	z-index: 2;
	list-style: none;
	color: var(--nav-secondary-color);
	justify-content: space-around;
	width: 50%;
	font-size: 18;
}

.nav-links svg {
	margin-bottom: -2px !important;
}

.nav-bar .darkLight-userToggle {
	display: flex;
	align-items: center;
}

.nav-links a,
.user-menu a {
	color: var(--nav-secondary-color);
	text-decoration: none;
	/* padding: 12px; */
	/* display: flex; */
	flex-direction: row;
	align-items: center;
	justify-content: space-around;
}

.link-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	padding-top: 1px;
	padding-left: 12px;
	padding-right: 12px;
	height: 40px;
	white-space: nowrap;
}

.nav-links li a:after {
	content: '';
	position: relative;
	display: block;
	width: 0;
	height: 2px;
	background: var(--nav-secondary-color);
	bottom: -1x;
	left: 50%;
	transition: all 0.3s;
}

.nav-links li a:hover:after {
	width: 100%;
	left: 0;
}

.burger {
	display: none;
	flex-direction: column;
	cursor: pointer;
}

.burger div {
	width: 22px;
	height: 3px;
	margin: 2px;
	background-color: var(--nav-secondary-color);
	transition: all 0.3s ease;
}

.nav-links svg {
	/* flex-direction: column; */
	/* display: flex; */
	margin: 0px;
	/* width: 21px;
	height: 21px; */
	color: var(--nav-secondary-color);
}

@media screen and (max-width: 1150px) {
	.nav-links {
		/* overflow-x: show; */
		/* overflow-y: hidden; */
		/* overflow: hidden; */
		/* right: 0px; */
		width: 100%;
		position: absolute;
		top: 58px;
		z-index: 2;
		list-style: none;
		color: var(--nav-secondary-color);
		background-color: var(--nav-color);
		display: flex;
		flex-direction: column;
		align-items: center;
		opacity: 0;
		pointer-events: none;
		border-bottom: 1px solid var(--border);
		border-top: none;
		padding-right: 14px;
		animation: navLinkBackFadeOut 0.4s ease;
	}

	.nav-links li {
		opacity: 0;
	}

	.burger {
		display: flex;
	}
}

.body-navbar-overflow {
	overflow-y: hidden;
}

.nav-active {
	display: flex;
	animation: navLinkBackFadeIn 0.4s ease;
	pointer-events: all;
	pointer-events: fill;
	opacity: 1;
	padding: 0;
	flex-direction: column;
	align-items: center;
	justify-content: space-evenly;
	height: 300px;
}

/* .body-overlay { */
/* color: rgba(0, 0, 0, 0.4); */
/* background-color: rgba(0, 0, 0, 0.4); */
/* } */

.close-display {
	display: flex;
}

@keyframes navLinkFade {
	from {
		opacity: 0;
		transform: translateX(5em);
	}
	to {
		opacity: 1;
		transform: translateX(0);
	}
}

@keyframes navLinkBackFadeIn {
	from {
		height: 0px;
	}
	to {
		height: 300px;
	}
}

@keyframes navLinkBackFadeOut {
	from {
		height: 300px;
	}
	to {
		height: 0px;
	}
}

.toggle .line1 {
	transform: rotate(-45deg) translate(-5px, 5px);
}
.toggle .line2 {
	opacity: 0;
}
.toggle .line3 {
	transform: rotate(45deg) translate(-5px, -5px);
}

.dark-light,
.user-toggle {
	height: 40px;
	width: 40px;
	color: var(--nav-secondary-color);
	display: flex;
	align-items: center;
	justify-content: center;
	/* margin: 0 5px; */
}

.dark-light div.moon-icon {
	opacity: 0;
	pointer-events: none;
}

.dark-light.active div.moon-icon {
	opacity: 1;
	pointer-events: auto;
}

.dark-light.active div.sun-icon {
	opacity: 0;
	pointer-events: none;
}

.dark-light div,
.user-toggle div {
	position: absolute;
	padding: 5px;
	color: var(--nav-secondary-color);
	font-size: 22px;
	cursor: pointer;
	transition: all 0.3s ease;
}

.user-toggle div.user-close {
	opacity: 0;
	pointer-events: none;
}

.user-toggle.active div.user-close {
	opacity: 1;
	pointer-events: auto;
}

.moon-icon svg,
.sun-icon svg,
.user-close svg,
.user-open svg {
	margin: 0px !important;
}

.user-toggle.active div.user-open {
	opacity: 0;
	pointer-events: none;
}

.user-toggle {
	/* z-index: 3; */
	position: relative;
}

h3 {
	margin: 5px;
	display: flex;
	flex-direction: row;
	align-items: center;
}

h3 svg {
	/* margin-top: 0px; */
	margin-left: -10px;
	/* margin: -2px; */
}

.usermenu-separator {
	/* max-width: 600px; */
	width: 260px;
	margin-bottom: 57px;
	border-bottom: 1px solid #adaeb1;
}

.user-toggle .user-menu {
	/* position: absolute; */
	/* position: column; */
	position: absolute;
	flex-direction: column;
	bottom: -266px;
	z-index: 2;
	/* bottom: -320px; */
	right: -2px;
	height: 240px;
	width: 295px;
	/* max-height: 300px; */
	/* padding-left: 20px; */
	display: flex;
	/* align-items: ; */
	background-color: var(--nav-color);
	padding: 15px;
	border-radius: 10px;
	border: 3px solid var(--nav-color);
	box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
	opacity: 0;
	justify-content: space-around;
	pointer-events: none;
	/* transition: all 0.3s ease; */
	animation: userMenuFadeOut 0.4s ease forwards;
}

.user-header {
	color: var(--nav-secondary-color);
	font-size: 22px;
	letter-spacing: 1px;
	/* height: 45px; */
	/* display: inline-block; */
	/* padding-bottom: 20px; */
	/* justify-content: space-around; */
	/* line-height: 4.5rem; */
	/* padding-bottom: 12px; */
	/* margin-left: 5px; */
	/* padding-left: 15px; */
	/* text-align: left; */
	/* pointer-events: none !important; */
	/* height: 2.5rem; */
	/* border-bottom: 0.5px solid rgba(197, 207, 211, 0.57); */
	/* width: 100%; */
}

.user-header .user-name {
	font-size: 17px;
	/* height: 25px; */
	/* display: flex;
	flex-direction: column; */
	/* justify-content: flex-end; */
	/* position: absolute; */
	/* width: 60%; */
	width: 180px;
	margin-left: 40px;
	/* margin-top: 10px; */
	/* margin-right: 15px; */
	/* margin-bottom: 5px; */
	/* margin-right: 25px; */
	/* font-weight: bold; */
	text-transform: uppercase;
	letter-spacing: 1.5px;
}

.user-toggle.active .user-menu {
	/* bottom: -265px; */
	opacity: 1;
	animation: userMenuFadeIn 0.3s ease;
	pointer-events: all;
}

@keyframes userMenuFadeIn {
	from {
		transform: translateY(35%);
	}
	to {
		transform: translateY(0%);
	}
}

@keyframes userMenuFadeOut {
	from {
		transform: translateY(0%);
	}
	to {
		transform: translateY(35%);
	}
}

.user-menu::before {
	content: '';
	overflow-y: visible;
	position: absolute;
	right: 12px;
	top: -8px;
	height: 14px;
	width: 14px;
	background-color: var(--nav-color);
	transform: rotate(-45deg);
}

.user-menu .user-link {
	/* color: var(--nav-secondary-color); */
	font-size: 17px;
	letter-spacing: 1px;
	padding-top: 15px;
	padding-bottom: 15px;
	padding-left: 20px;
	padding-right: 20px;
	/* display: flex; */
	/* flex-direction: row;
	align-items: baseline; */
	/* padding-top: 12px; */
}

#root > nav > div.user-toggle.active > div.user-menu > a:nth-child(4) > svg {
	margin-bottom: -4px !important;
}

.user-link svg {
	margin-bottom: -3px !important;
	margin-right: 18px !important;
}

.user-toggle.active .user-menu .user-link {
	pointer-events: auto;
}

.user-menu a {
	padding-left: 7px;
}

.clicked,
.user-menu .user-link:hover {
	/* color: var(--nav-color); */
	background-color: var(--button-color);
	border-radius: 10px;
	/* font-size: 17px; */
}

option.separator {
	/* border: ; */
	align-items: center;
	clear: both;
	display: block;
	/* width: 96%; */
	padding-left: 16px;
	/* color: var(--nav-color); */
	border-bottom: var(--nav-secondary-color);
	height: 0.5px;
	/* border-bottom: 50px solid var(--nav-secondary-color);
	background-color: var(--hover-button); */
}
