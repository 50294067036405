@import url('https://fonts.googleapis.com/css2?family=Niramit:wght@300;400;500&display=swap');

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	/* font-family: 'Arial', sans-serif; */
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Helvetica Neue', Arial,
		sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
	transition: all 0.4s ease;
}

:root {
	--body-color: #679fc7;
	--nav-color: #4276bc;
	--text-color: #fff;
	--nav-secondary-color: rgb(226, 226, 226);
	--search-text: #adaeb1;
	--button-color: #3d7ccf;
	--hover-button: #2a7be4;
	--disabled-button: #7a9eca;
	--border: rgba(0, 0, 0, 0.3);
	--toastify-text-color-light: black;
	--toastify-text-color-dark: black;
	--inner-spinner: #c9c9c9;
}

body {
	background-color: var(--body-color);
}

body.dark {
	--body-color: #252627;
	--nav-color: #18191a;
	--text-color: #ccc;
	--search-bar: #242526;
	--button-color: #303035;
	--hover-button: #18191a;
	--disabled-button: #606063;
	--border: #393838;
	--inner-spinner: #8b8b8b;
}

.layout {
	padding: 1em;
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: calc(100vh - 126px);
}

.separator {
	max-width: 600px;
	width: 100%;
	border-bottom: 1px solid #adaeb1;
}

.Toastify__toast--success,
.Toastify__toast--error,
.Toastify__toast--warning {
	border-radius: 10px;
	font-size: 16px;
	color: #18191a;
	box-shadow: 0 5px 10px rgb(0 0 0 / 30%);
	background-color: var(--text-color);
}

.Toastify__toast-icon {
	width: 22px;
	margin-left: 5px;
	margin-right: 18px;
}

.footer {
	height: 66px;
	/* width: 100%; */
	color: white;
	/* background-color: #e8f0fe; */
	background-color: var(--nav-color);
	display: flex;
	/* flex-direction: row; */
	justify-content: space-around;
	border-top: 1px solid var(--border);
	animation: bgFadeIn 0.7s ease;
}

.ft-text,
.ft-logo {
	justify-content: center;
	align-self: center;
}

.ft-logo {
	height: 58px;
	display: flex;
}
