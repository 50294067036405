.new-change-data {
	display: flex;
	flex-direction: row;
	align-content: center;
	justify-content: space-around;
	/* padding: 10px; */
}

.dropdown-container {
	display: flex;
	align-items: center;
	/* height: 44px; */
	/* max-width: 250px; */
	/* margin: 8px 0; */
	position: relative;
	/* border: 1px solid rgba(0, 0, 0, 0.2); */
	border-radius: 5px;
}

.user-section {
	width: 22em;
	padding: 10px;
}

.user-section-schedule-search {
	width: 28em;
	padding: 10px;
}

.user-profile-section {
	padding: 10px;
	width: 20em;
	height: 9em;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

/* .section-icon {
	width: 20%;
	display: flex;
	flex-direction: column;
	justify-content: center;
} */

.user-section-content {
	font-size: 22px;
	letter-spacing: 1px;
	height: 82px;
	display: flex;
	flex-direction: row;
	align-content: center;
	/* display: inline-block; */
	/* padding-bottom: 20px; */
	/* justify-content: space-around; */
	/* line-height: 4.5rem; */
	/* margin-left: 5px; */
	padding: 5px;
	/* text-align: left; */
	/* pointer-events: none !important; */
	/* height: 2.5rem; */
	border: 0.5px solid var(--search-text);
	border-radius: 10px;
	/* text-overflow: clip; */
	/* max-height: 10em; */
}

.user-section-comment {
	font-size: 22px;
	letter-spacing: 1px;
	padding: 5px;
	display: flex;
	flex-direction: row;
	align-content: center;
	border: 0.5px solid var(--search-text);
	border-radius: 10px;
}

.user-section-profile {
	font-size: 22px;
	letter-spacing: 1px;
	height: 82px;
	display: flex;
	flex-direction: column;
	align-content: center;
	justify-content: center;
	padding: 5px;
	/* text-align: left; */
	/* pointer-events: none !important; */
	/* height: 2.5rem; */
	border-radius: 10px;
}

.section-icon-comment {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	font-size: 19px;
	padding: 10px;
	width: 70px;
}

.new-change-comment-data {
	/* width: 80%; */
	padding-top: 10px;
	padding-bottom: 10px;
	padding-left: 20px;
	padding-right: 20px;
	text-overflow: clip;
	/* min-height: 10em; */
}

.section-text,
.section-icon {
	display: flex;
	flex-direction: column;
	justify-content: center;
	font-size: 19px;
	padding: 10px;
}

.section-text-comment {
	/* display: flex; */
	flex-direction: column;
	font-size: 19px;
	padding: 10px;
}

.section-text-profile {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	font-size: 20px;
	padding: 10px;
}

.section-icon {
	align-items: center;
	width: 20%;
}

.user-section .section-title {
	font-size: 16px;
	font-weight: bold;
}

.user-change-section {
	/* background-color: ; */
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-around;
}

.comment-load {
	display: flex;
	align-items: center;
	height: 40px;
	/* width: 100%; */
	margin: 5px 0 5px 0;
	position: relative;
	border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.comment-load input {
	height: 100%;
	width: 100%;
	outline: none;
	border: none;
	font-size: 16px;
	background-color: var(--text-color);
	/* font-weight: 400; */
	/* border: 2px solid rgba(0, 0, 0, 0.2); */
	/* transition: all 0.3s ease; */
}

.section-comment-data {
	text-overflow: hidden;
}

.section-text-comment {
	width: 100%;
}

.new-change-comment-data {
	width: 44em;
	padding: 10px;
}

@media only screen and (max-width: 780px) {
	.new-change {
		/* min-width: 99.9vmin; */
		overflow: hidden;
	}

	.new-change-data {
		display: flex;
		flex-direction: column;
	}

	.form {
		width: 430px;
	}

	/* .inputs-col {
		padding-left: 0px;
		padding-right: 0px;
	} */

	/* .section-icon-comment {
		width: 30%;
	} */

	.new-change-comment-data {
		width: 22em;
		padding: 10px;
	}
}
