.dropdown-wrapper {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 0 10px;
	/* margin: 0px 0 18px 0; */
	display: flex;
	/* align-items: center; */
	/* height: 44px; */
	/* max-width: 250px; */
	/* margin: 8px 0; */
	position: relative;
	/* border: 1px solid rgba(0, 0, 0, 0.2); */
	border-radius: 5px;
	/* padding: 0 10px; */
	cursor: pointer;
	/* margin: 18px 0 18px 0; */
	/* z-index: 1; */
}

.dropdown-icon {
	width: 25%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	padding-right: 15px;
	/* height: 30px; */
}

.dropdown-title {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	/* max-width: 100%; */
	/* justify-content: space-between; */
	font-size: 18px;
}

.dropdown-text {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}
/* 
.dropdown-arrow {
	padding: 0px;
} */

.dropdown-button {
	display: flex;
	flex-direction: row;
	/* height: 35px; */
	align-items: center;
	justify-content: space-between;
	/* outline: none; */
	/* padding-right: 10px; */
	font-size: 16px;
	font-weight: 400;
	color: rgba(0, 0, 0, 0.65);
	/* border: 2px solid rgba(0, 0, 0, 0.2); */
	transition: all 0.3s ease;
}

.dropdown-content {
	/* position: relative; */
	/* position: relative; */
	z-index: 0;
	position: absolute;
	/* top: -20px; */
	/* bottom: -20px; */
	flex-direction: column;
	/* bottom: -8px; */
	/* width: calc(100% - 43px); */
	/* height: 250px; */
	/* min-width: 252px; */
	left: 0px;
	/* bottom: -158px; */
	width: 100%;
	/* align-items: ; */
	background-color: var(--text-color);
	/* padding: 15px; */
	border-radius: 10px;
	border: 1px solid rgba(0, 0, 0, 0.2);
	/* border-top: none; */
	box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
	opacity: 0;
	justify-content: space-around;
	pointer-events: none;
	transform-origin: top center;
	animation: shrinkUp 0.3s ease-out forwards;
	/* display: none; */
}

.dropdown-button.selected {
	color: rgba(0, 0, 0, 1) !important;
	/* background-color: #fff; */
	/* width: 99%; */
}

.dropdown-content.active {
	opacity: 1;
	pointer-events: all;
	z-index: 2;
	display: block;
	animation: growDown 0.3s ease-in forwards;
}

.dropdown-content::-webkit-scrollbar {
	width: 10px;
}
.dropdown-content::-webkit-scrollbar-track {
	border: 1px solid rgba(179, 179, 179, 0.5);
	/* border: 1px solid rgba(0, 0, 0, 0.2); */
	background: #f1f1f1;
	border-radius: 25px;
}
.dropdown-content::-webkit-scrollbar-thumb {
	border: 1px solid rgba(179, 179, 179, 1);
	/* border: 1px solid rgba(0, 0, 0, 0.2); */
	background: #ccc;
	border-radius: 25px;
}
.dropdown-content::-webkit-scrollbar-thumb:hover {
	background: #b3b3b3;
}

@keyframes growDown {
	0% {
		transform: scaleY(0);
	}
	100% {
		transform: scaleY(1);
		display: block;
	}
}

@keyframes shrinkUp {
	0% {
		transform: scaleY(1);
	}
	100% {
		transform: scaleY(0);
		display: none;
	}
}

.dropdown-arrow.active {
	transform: rotateZ(-180deg);
}

.dropdown-option {
	height: 50px;
	/* width: 100%; */
	outline: none;
	border: none;
	overflow-x: auto;
	/* padding: 5px; */
	font-size: 18px;
	list-style: none;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	text-align: center;
	cursor: pointer;
	/* border: 2px solid rgba(0, 0, 0, 0.2); */
	transition: all 0.3s ease;
}

.dropdown-input {
	height: 50px;
	background-color: var(--text-color);
	outline: none;
	border: none;
	font-size: 18px;
	display: flex;
	flex-direction: row;
}

.dropdown-input-icon {
	width: 10%;
	margin-left: 5px;
	margin-right: 5px;
}

.dropdown-input-icon svg {
	color: slategray !important;
}

.dropdown-input-box {
	border: 2px solid rgba(0, 0, 0, 0.2);
	width: 100%;
	display: flex;
	padding-left: 10px;
	padding-right: 10px;
	flex-direction: row;
	align-items: center;

	/* cursor: inherit; */
}

.dropdown-content.active .dropdown-option:hover {
	background-color: rgb(243, 243, 243);
	border-radius: 10px;
}

/* .menu-content {
	height: 100%;
	width: 100%;
} */

/* @keyframes menuFadeIn {
	from {
		transform: translateY(100%);
	}
	to {
		transform: translateY(0%);
	}
}

@keyframes menuFadeOut {
	from {
		transform: translateY(0%);
	}
	to {
		transform: translateY(100%);
	}
} */
