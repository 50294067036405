.spinner1,
.spinner2,
.spinner3 {
	border-radius: 50%;
	z-index: 2;
	animation: spinner 0.5s linear infinite;
}

.spinner1 {
	width: 70px;
	height: 70px;
	top: 40%;
	left: 40%;
	/* margin-left: -10px;
	margin-top: -10px; */
	border: 8px solid var(--inner-spinner);
}

.spinner2,
.spinner3 {
	width: 20px;
	height: 20px;
}

.spinner2 {
	border: 2px solid var(--nav-color);
}

.spinner3 {
	border: 2px solid #ddd;
}

@keyframes spinner {
	100% {
		transform: rotate(360deg);
	}
}
