.schedule-calendar {
	display: flex;
	flex-direction: row;
	justify-content: center;
	margin: 20px;
}

.schedule-calendar .react-calendar {
	width: 450px;
}

.schedule-search-container {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	/* width: 100%; */
}

.schedule-close-button {
	display: flex;
	height: 100%;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

/* .schedule-calendar .react-calendar__navigation,
.schedule-calendar .react-calendar__navigation, */
.schedule-calendar .react-calendar__month-view__weekdays,
.schedule-calendar .react-calendar__navigation {
	height: 3em;
	margin: 0;
	align-items: center;
}

.schedule-calendar .react-calendar__month-view__days {
	height: 15em;
}
