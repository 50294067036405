.modal-bg {
	background-color: rgba(0, 0, 0, 0.5);
	width: 100%;
	height: 100vh;
	top: 0;
	left: 0;
	z-index: 3;
	display: none;
	/* opacity: 0; */
	position: fixed;
	justify-content: center;
	align-items: center;
	transition: all 0.4s ease;
}

.modal-content {
	padding-left: 1.6em;
	padding-right: 1.6em;
	padding-top: 0.8em;
	padding-bottom: 0.8em;
	background-color: var(--text-color);
	/* position: absolute; */
	/* bottom: 10em; */
	/* right: 50vw; */
	/* max-height: ; */
	min-height: 250px;
	max-height: 450px;
	/* width: 400px; */
	min-width: 350px;
	max-width: 500px;
	/* height: 240px; */
	/* min-width: 250px; */
	/* background: ; */
	flex-direction: column;
	justify-content: space-around;
	display: flex;
	/* opacity: 0; */
	/* align-items: center; */
	border-radius: 16px;
	border: 1px solid rgba(0, 0, 0, 0.15);
	box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
	transition: all 0.4s ease;
}

.modal-bg.active {
	display: flex;
}

.modal-header {
	padding-bottom: 5px;
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	height: 57px;
}

.modal-body {
	display: flex;
	flex-direction: column;
	justify-content: center;
	font-size: 18px;
	text-align: center;

	min-height: 57px;
}

.modal-body-padding {
	padding-top: 10px;
	padding-bottom: 10px;
}

.modal-title {
	font-weight: 500;
	font-size: 20px;
	padding-right: 10px;
}

.modal-divider {
	width: 100%;
	border-top: 1px solid rgba(0, 0, 0, 0.15);
}

.modal-footer {
	display: flex;
	width: 100%;
	flex-direction: row;
	justify-content: flex-end;
	/* height: 50px; */
	/* pointer-events: fill; */
}

.modal-btn {
	padding-left: 8px;
	padding-right: 8px;
}

.comment-modal {
	display: flex;
	align-items: center;
	height: 40px;
	/* width: 100%; */
	margin: 5px 0 5px 0;
	position: relative;
	/* border: 1px solid rgba(0, 0, 0, 0.2); */
}

.comment-modal input {
	height: 100%;
	width: 100%;
	outline: none;
	border: none;
	font-size: 16px;

	/* font-weight: 400; */
	/* border: 2px solid rgba(0, 0, 0, 0.2); */
	/* transition: all 0.3s ease; */
}

@keyframes modalFadeIn {
	from {
		/* opacity: 0; */
		transform: translateY(-50%);
	}
	to {
		/* opacity: 1; */
		transform: translateY(0%);
	}
}

@keyframes modalFadeOut {
	from {
		/* opacity: 1; */
		transform: translateY(0%);
	}
	to {
		/* opacity: 0; */
		transform: translateY(50%);
	}
}

@keyframes bgFadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@keyframes bgFadeOut {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
	}
}
