.log-container {
	display: flex;
	flex-direction: column;
	max-height: 14.5em;
	overflow-y: auto;
}

.event-box {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	margin: 10 5 10 0;
	max-height: 7.5em;
}

.event-border {
	border: 0.1px solid rgba(238, 238, 238, 0.5);
}

.detail-list {
	display: flex;
	flex-direction: column;
}

.detail {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
}

.data-container {
	padding-left: 2px;
	padding-right: 2px;
	margin: 5px;
	display: flex;
	align-items: center;
}

.changelog-text {
	text-align: left;
}

.date-time {
	width: 100%;
	display: flex;
	flex-direction: row;
}

.icon-container {
	padding-right: 15px;
	min-width: 36px;
}

.icon-container.time {
	padding-right: 10px;
}

.log-container::-webkit-scrollbar {
	width: 10px;
}

.log-container::-webkit-scrollbar-track {
	border: 1px solid rgba(179, 179, 179, 0.5);
	background: #f1f1f1;
	border-radius: 25px;
}

.log-container::-webkit-scrollbar-thumb {
	border: 1px solid rgba(179, 179, 179, 1);
	background: #ccc;
	border-radius: 25px;
}

.log-container::-webkit-scrollbar-thumb:hover {
	background: #b3b3b3;
}
