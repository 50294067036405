.new-form {
	padding: 1em;
	/* width: 100%; */
	/* pointer-events: auto; */
	background-color: var(--text-color);
	background-clip: padding-box;
	border: 1px solid rgba(0, 0, 0, 0.15);
	border-radius: 1rem;
	outline: 0;
	overflow-x: hidden;
	/* max-height: 60em; */
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-around;
	animation: bgFadeIn 0.6s ease;
}

form {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.form.login,
.form.register,
.form.profile-edit,
.form.change-password,
.form.new-password,
.form.forgot-password {
	padding: 10px;
}

.form.profile-edit,
.form.register {
	width: 800px;
}

.form.login,
.form.forgot-password {
	width: 420px;
	padding: 20px;
}

.form.login.loading,
.form.register.loading {
	opacity: 0.4;
}

.inputs-container.register,
.inputs-container.profile-edit {
	/* width: 800px; */
	display: flex;
	flex-direction: row;
	align-content: center;
	justify-content: center;
}

.inputs-group {
	width: 360px;
	padding-left: 10px;
	padding-right: 10px;
}

@media only screen and (max-width: 440px) {
	.new-form {
		min-width: 99.9vmin;
		overflow: hidden;
	}

	.inputs-container.register,
	.inputs-container.profile-edit {
		display: flex;
		flex-direction: column;
	}

	.form.register,
	.form.profile-edit {
		width: 100%;
	}

	.form.login,
	.form.forgot-password,
	.form.new-password {
		width: 350px;
	}

	.inputs-group {
		width: 335px;
		padding-left: 15px;
		padding-right: 15px;
	}
}

.form-footer,
.remember-me {
	display: flex;
	align-items: center;
	padding: 10px 0 10px 0;
	justify-content: center;
}

.form-footer {
	height: 65px;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	width: 100%;
}

.check-space {
	margin-left: 7px;
	padding-right: 5px;
	margin-top: -2px;
}
