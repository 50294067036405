.spinner-container-change,
.spinner-container-affected,
.loading-error-change,
.loading-error-affected {
	display: flex;
	justify-content: center;
	align-items: center;
}

.spinner-container-change,
.loading-error-change {
	margin-bottom: 20px;
	min-height: 67vh;
}

.spinner-container-affected,
.loading-error-affected {
	min-height: 79vh;
}

.spinner-container-main {
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: calc(100vh - 126px);
}

.spinner-container-shared {
	margin-bottom: 3.9em;
}

.changes-list {
	/* z-index: 1; */
	/* position: relative;
	display: -ms-flexbox;
	-ms-flex-direction: row;
	flex-direction: row; */
	padding: 1em;
	/* width: 85%; */
	/* pointer-events: auto; */
	background-color: var(--text-color);
	background-clip: padding-box;
	border: 1px solid rgba(0, 0, 0, 0.15);
	border-radius: 1rem;
	outline: 0;
	/* min-width: 97vmin; */
	width: 80em;
	min-height: 83.47vh;
	animation: bgFadeIn 0.6s ease;
}

.tabs-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 80px;
	width: 100%;
	flex-wrap: wrap;
}

.tabs-list {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	height: 60px;
	width: 100%;
	/* border-bottom: 1px solid #ccc; */
}

.tab {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	font-weight: bold;
	margin: 10px;
	font-size: 26;
	align-items: center;
	height: 100%;
	width: 35%;
	cursor: pointer;
	text-decoration: none;
	border-bottom: 3px solid var(--text-color);
	/* transition: 0.2s; */
}

.tab a {
	text-decoration: none;
	color: black;
}

.tab.selected {
	border-bottom: 3px solid var(--nav-color);
}

@media screen and (max-width: 440px) {
	.changes-list {
		min-width: 99.9vmin;
		overflow: hidden;
	}

	.tab {
		padding: 8px;
		margin: 8px;
	}

	.tabs-list {
		flex-direction: column;
		justify-content: center;
		height: 100%;
	}

	.tabs-container {
		height: 100%;
		justify-content: space-around;
	}

	.tab.selected {
		min-width: 45%;
		max-width: 50%;
	}
}
