.changes-filters {
	margin: 10px;
	/* height: 50px; */
	/* flex-wrap: wrap; */
	/* width: 100%; */
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: center;
}

.changes-filter {
	/* padding: 5px; */
	/* width: 100px; */
	margin: 10px;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	cursor: default;
}

.radio-buttons {
	width: 30%;
	padding: 6px 10px 2px 10px;
	display: flex;
	flex-direction: row;
	justify-content: space-around;
}

.changes-radio-icon {
	margin-right: 15px;
}

.changes-search-box {
	border: 2px solid rgba(0, 0, 0, 0.2);
	/* min-width: 2em; */
	max-width: 25em;
	width: 38%;
	display: flex;
	padding-left: 10px;
	padding-right: 10px;
	margin: 10px;
	flex-direction: row;
	align-items: center;
	border-radius: 5px;
}

.changes-search-icon {
	width: 25px;
}

.changes-search {
	/* margin-left: 10px; */
	height: 40px;
	width: 90%;
	background-color: var(--text-color);
	outline: none;
	border: none;
	font-size: 18px;
	display: flex;
	flex-direction: row;
}

.no-data {
	display: flex;
	flex-direction: column;
	min-height: 55vh;
	justify-content: center;
	text-align: center;
}

.table-schedule-full {
	display: none;
}

.table-schedule-week > table {
	border: none;
}

.table-schedule-full tr .row-data,
.table-schedule-week tr .row-data {
	cursor: pointer;
}

.table-user > tbody > tr {
	height: 65px;
}

tr td.row-data.shift {
	background-color: var(--nav-color);
	opacity: 0.96;
	color: white;
}

.table-schedule-week tr .row-data.shift {
	cursor: auto;
}

table > thead > {
	height: 41px;
}

.table-change > thead > tr > th:nth-child(7),
.table-change > thead > tr > th:nth-child(8),
.table-user > thead > tr > th:nth-child(6),
.table-user > thead > tr > th:nth-child(7) {
	text-align: center;
}

h2 {
	display: flex;
	flex-direction: row;
	align-self: center;
}

table {
	width: 100%;
	border-collapse: collapse;
	/* text-align: center; */
}

/* Zebra striping */
tr:nth-of-type(odd) {
	background: rgb(243, 243, 243);
}

body.dark tr:nth-of-type(even) {
	background: rgba(233, 230, 230, 0.774);
}

body.dark tr:nth-of-type(even):hover {
	background-color: #b1dffa;
}

th {
	background-color: var(--nav-color);
	opacity: 0.96;
	color: white;
}

.table-header {
	padding-left: 5px;
	padding-right: 5px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	/* min-width: 2.7rem; */
}

.arrow-down {
	display: flex;
	align-items: flex-end;
	pointer-events: none;
	border: none;
	background-color: var(--nav-color);
	color: var(--nav-secondary-color);
	opacity: 0;
}

.arrow-down.arrow-active {
	opacity: 1;
}

.arrow-down.arrow-active.click {
	transform: rotateZ(180deg);
}

/* th .arrow-down:hover {
	cursor: default;
} */

th .arrow:focus {
	outline: 1px dotted white;
}

td,
th {
	padding: 8px;
	border: 1px solid var(--search-text);
	text-align: left;
	font-size: 18px;
}

th .schedule {
	text-align: center;
	font-size: 18px;
}

.table-schedule-week > table > thead > tr > th,
.table-schedule-week > table > tbody > tr > td {
	text-align: center;
}

.table-change > tbody > tr:hover,
.table-request > tbody > tr:hover,
.table-affected > tbody > tr:hover,
.table-user > tbody > tr:hover {
	background-color: #b1dffa;
}

.table-schedule-full tr td:hover,
.table-schedule-week tr td:hover {
	background-color: #b1dffa;
}

.table-schedule-full tr td {
	text-align: center;
}

.change-detail ul {
	list-style: none;
	margin: 0;
	padding: 0px;
}

.col-name {
	display: none;
	color: #000;
	font-weight: bold;
}

.table-schedule-full {
	display: none;
}

/* .table-change > thead {
	height: 80px;
} */

@media only screen and (max-width: 777px) {
	td {
		display: inline-block;
	}
	td:nth-child(1) {
		clear: both;
	}

	.changes-filters {
		flex-direction: column;
		/* justify-content: center; */
		/* margin-right: 10px; */
		width: 94%;
	}

	.changes-search-box {
		width: 70%;
		margin: 10px;
	}

	table {
		text-align: left;
	}
}

@media only screen and (max-width: 960px),
	(min-device-width: 876px) and (max-device-width: 960px) {
	.table-schedule-week th {
		padding: 3px;
	}
}
/* 
Max width before this PARTICULAR table gets nasty
This query will take effect for any screen smaller than 760px
and also iPads specifically.
*/
@media only screen and (max-width: 877px),
	(min-device-width: 768px) and (max-device-width: 877px) {
	table {
		width: 100%;
	}

	/* Force table to not be like tables anymore */

	thead,
	tbody,
	th,
	td,
	tr {
		display: block;
		/* z-index: 0; */
	}

	.table-schedule-week {
		display: none;
	}

	.table-schedule-full {
		display: flex;
	}

	/* Hide table headers (but not display: none;, for accessibility) */

	.table-schedule-full thead tr {
		position: absolute;
		top: -9999px;
		left: -9999px;
	}

	tr {
		border: 1px solid #ccc;
	}

	td {
		/* Behave  like a "row" */
		border: none;
		/* border-bottom: 1px solid #eee; */
		/* display: flex; */
		/* flex-direction: row; */
		/* align-content: center; */
		/* position: relative; */
		/* padding-left: 35%; */
		/* min-height: 70px; */
	}

	td div.options {
		padding: 5px;
	}

	.data-col {
		width: 65%;
		/* display: -webkit-box; */
		max-width: 51vmin;
		/* -webkit-line-clamp: 2; */
		/* -webkit-box-orient: vertical; */
		overflow: hidden;
		/* max-width: fit-content; */
	}

	.row-data {
		display: flex;
		flex-direction: row;
		align-items: center;
		height: 70px;
		/* width: 90%; */
	}

	.col-name {
		display: flex;
		margin-left: 10px;
		margin-right: 15px;
		width: 30%;
	}

	tr td.row-data.shift {
		background-color: var(--nav-color);
		opacity: 0.96;
		cursor: auto;
	}

	tr td.row-data.shift,
	tr td.row-data.shift .col-name {
		color: white;
	}

	.table-schedule-full > table > thead > tr > th,
	.table-schedule-full > table > tbody > tr > td {
		text-align: start;
	}

	.table-change > thead > tr > th:nth-child(7),
	.table-change > thead > tr > th:nth-child(8),
	.table-request > thead > tr > th:nth-child(6),
	.table-affected > thead > tr > th:nth-child(8),
	.table-affected > thead > tr > th:nth-child(8),
	.table-user > thead > tr > th:nth-child(6),
	.table-user > thead > tr > th:nth-child(7) {
		display: none;
		/* display: flex;
		flex-direction: row;
		justify-content: flex-start;
		padding-left: 13px; */
	}

	tr > td:nth-child(8) {
		padding-bottom: 18px;
	}
}

tr td.row-data.shift {
	background-color: var(--nav-color);
	opacity: 0.96;
	cursor: auto;
}

tr td.row-data.past {
	background-color: gray;
}

tr td.row-data.selected {
	background-color: #006edc;
	color: white;
}

tr td.row-data.work-user {
	background-color: var(--disabled-button);
}

tr td.row-data.work-user {
	background-color: var(--disabled-button);
}

.table-change > thead > tr {
	min-height: fit-content;
}
